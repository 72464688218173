import api, { getApiError } from "@/api";
import { WeekStartDay, AppLanguage } from "@/types/common";
import { format } from "date-fns";

export async function getAuthStatus() {
  const { data, error } = await api.GET("/api/v1/auth/status");
  if (error || !data) {
    throw getApiError(error);
  }
  return data;
}

export async function authenticate(idToken: string) {
  const { data, error } = await api.POST("/api/v1/auth/authenticate", {
    body: {
      method: "GOOGLE",
      token: idToken,
    },
  });
  if (error || !data) {
    throw getApiError(error);
  }
  return data;
}

export async function register(args: {
  name: string;
  dateOfBirth?: Date;
  language: AppLanguage;
  weekStartDay: WeekStartDay;
  timezone: string;
  idToken: string;
  whereDidYouHear: string;
}) {
  const dateOfBirthString = args.dateOfBirth
    ? format(args.dateOfBirth, "yyyy-MM-dd")
    : undefined;
  const { data, error } = await api.POST("/api/v1/auth/register", {
    body: {
      auth: { method: "GOOGLE", token: args.idToken },
      name: args.name,
      dateOfBirth: dateOfBirthString,
      language: args.language,
      weekStartDay: args.weekStartDay,
      timezone: args.timezone,
      whereDidYouHear: args.whereDidYouHear,
    },
  });
  if (error || !data) {
    throw getApiError(error);
  }
}

export async function migrateRegister(args: { idToken: string }) {
  const { data, error } = await api.POST("/api/v1/auth/migrate-register", {
    body: {
      auth: { method: "GOOGLE", token: args.idToken },
    },
  });
  if (error || !data) {
    throw getApiError(error);
  }
}

export async function logout() {
  const { data, error } = await api.POST("/api/v1/auth/logout");
  if (error || !data) {
    throw getApiError(error);
  }
}
