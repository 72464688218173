import api, { getApiError } from "@/api";
import { ThemeMode, WeekStartDay, AppLanguage } from "@/types/common";

export interface UpdateUserSettingsRequest {
  weekStartDay?: WeekStartDay;
  timezone?: string;
  language?: AppLanguage;

  allowWeeklyReviewEmails?: boolean;
  weeklyReviewEmailTime?: string;

  themeMode?: ThemeMode;
}

export async function updateUserSettings(request: UpdateUserSettingsRequest) {
  const { data, error } = await api.PATCH("/api/v1/user/settings", {
    body: request,
  });
  if (error || !data) {
    throw getApiError(error);
  }
  return data;
}
